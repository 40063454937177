body {
  overflow-x: hidden;
}

@font-face {
  font-family: 'Bozon';
  font-weight: 400;
  src: url('./assets/fonts/Bozon-Regular.woff2') format('woff2'),
    url('./assets/fonts/Bozon-Regular.woff') format('woff'),
    url('./assets/fonts/Bozon-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Bozon';
  font-weight: 500;
  src: url('./assets/fonts/Bozon-Bold.woff2') format('woff2'),
    url('./assets/fonts/Bozon-Bold.woff') format('woff'),
    url('./assets/fonts/Bozon-Bold.ttf') format('truetype');
}

@font-face {
  font-family: 'Bozon';
  font-weight: 600;
  src: url('./assets/fonts/Bozon-DemiBold.woff2') format('woff2'),
    url('./assets/fonts/Bozon-DemiBold.woff') format('woff'),
    url('./assets/fonts/Bozon-DemiBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Bozon';
  font-weight: 700;
  src: url('./assets/fonts/Bozon-ExtraBold.woff2') format('woff2'),
    url('./assets/fonts/Bozon-ExtraBold.woff') format('woff'),
    url('./assets/fonts/Bozon-ExtraBold.ttf') format('truetype');
}
